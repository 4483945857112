<template>
  <available-components-for-header
    v-if="headerMenuComponents && headerMenuComponents.length"
    :specific-components="headerMenuComponents"
  />
</template>

<script lang="ts">
import AvailableComponentsForHeader from '~/components/theme4/AvailableComponentsForHeader.vue';
import { TheHeaderMixin } from '~/units/core/mixins/theHeader.mixin';

export default defineNuxtComponent({
  name: 'Theme4BaseHeaderTheHeader',
  components: { AvailableComponentsForHeader },

  mixins: [TheHeaderMixin],
});
</script>

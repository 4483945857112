<template>
  <div
    v-if="vars.isVisibleBool"
    :style="`background-color: var(--c-${vars.backgroundColor});
    color: ${vars.textColor ? vars.textColor : 'var(--c-text-secondary)'}`"
    class="tw-mb-10 tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 tw-p-3 tw-text-center tw-duration-500"
    :class="{
      'tw-pointer-events-none tw-opacity-0': vars.visibleAfterScrollBool,
      'tw-pointer-events-auto tw-opacity-100': vars.visibleAfterScrollBool && isBannerVisible,
    }"
  >
    <h6>{{ vars.descriptionText }}</h6>
    <nuxt-link :class="`n-${vars.buttonTypeText} n-small`" :to="vars.buttonLinkUrl" :external="true">
      {{ vars.buttonLabelText }}
    </nuxt-link>
  </div>
</template>
<script lang="ts">
export default defineNuxtComponent({
  props: {
    vars: {
      required: true,
    },
  },

  data() {
    return {
      isBannerVisible: false,
      scrollThreshold: 350,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleBanner);
    this.handleBanner();
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleBanner);
  },

  methods: {
    handleBanner() {
      this.isBannerVisible = window.scrollY > this.scrollThreshold;
    },
  },
});
</script>
